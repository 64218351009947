import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { ACTIONS, UserActions } from './userReducer';
import { User } from '../../models';
import { Select } from '../common/select/Select';
import { receptionPlaces } from '../../utils/calculatorInfo';

interface InfoTabProps {
  userState: User;
  userDispatch: React.Dispatch<UserActions>;
  onSubmit?: (e: React.FormEvent<any>) => void;
  disabledButton?: boolean;
}

function InfoTab({
  userState,
  userDispatch,
  onSubmit,
  disabledButton,
}: InfoTabProps) {
  // Manejo de la dirección
  const updateAddress = (key: string, value: string) => {
    const updatedAddress = { ...userState.addresses[0], [key]: value };
    userDispatch({
      type: ACTIONS.SET_ADDRESSES,
      payload: [updatedAddress],
    });
  };

  return (
    <div className="flex flex-col p-8 w-full bg-white rounded-md shadow-md md:w-1/2">
      {/* logo */}
      <div className="flex items-center justify-start w-40 h-fit">
        <img
          src="/media/logos/horizontal.png"
          alt="logo"
          className="h-full w-full object-contain"
        />
      </div>
      {/* titulo */}
      <div className="flex items-center justify-start">
        <h1 className="text-xl font-medium">Completa tu perfil</h1>
      </div>
      <form onSubmit={onSubmit} className="flex flex-col  gap-4 ">
        {/* Dirección */}
        <div className="flex flex-col gap-2">
          <h2>Dirección</h2>
          {/* label */}
          <label className="text-sm text-neutral-900 font-normal">
            Línea 1
          </label>
          <Input
            placeholder="Línea 1"
            value={userState.addresses[0]?.firstLine || ''}
            onChange={(e) => updateAddress('firstLine', e.target.value)}
          />
          {/* label */}
          <label className="text-sm text-neutral-900 font-normal">
            Punto de Referencia
          </label>
          <Input
            placeholder="Punto de Referencia"
            value={userState.addresses[0]?.secondLine || ''}
            onChange={(e) => updateAddress('secondLine', e.target.value)}
          />

          {/* Estado */}
          {/* <label className="text-sm text-neutral-900 font-normal">Estado</label> */}
          <Select
            label="Estado"
            labelClassName="w-full px-2 text-sm font-medium"
            onChange={(e) => {
              // Nota: e.preventDefault(); ha sido removido
              updateAddress('state', e.target.value);
            }}
            value={userState.addresses[0]?.state ?? ''}
          >
            <option key="none">-</option>
            {receptionPlaces?.map((place) => (
              <option key={place.value} value={place.value}>
                {place.name}
              </option>
            ))}
          </Select>

          {/* <Input
            placeholder="Estado"
            value={userState.addresses[0]?.state || ''}
            onChange={(e) => updateAddress('state', e.target.value)}
          /> */}
          {/* Ciudad */}
          <label className="text-sm text-neutral-900 font-normal">Ciudad</label>
          <Input
            placeholder="Ciudad"
            value={userState.addresses[0]?.city || ''}
            onChange={(e) => updateAddress('city', e.target.value)}
          />
          {/* Código postal */}
          <label className="text-sm text-neutral-900 font-normal">
            Código postal
          </label>
          <Input
            placeholder="Código postal"
            value={userState.addresses[0]?.postalCode || ''}
            onChange={(e) => updateAddress('postalCode', e.target.value)}
          />
        </div>

        {/* Teléfono */}
        <div className="w-full ">
          <label className="text-sm text-neutral-900 font-normal">
            Teléfono
          </label>
          <PhoneInput
            inputStyle={{
              appearance: 'none',
              height: '24px',
              background: 'transparent',
              width: '100%',
              border: 'solid',
              borderWidth: '1px',
              borderColor: '#EAEAEA',
              borderRadius: '4px',
              minHeight: '40px',
              fontSize: '15px',
              color: '#292727',
              fontWeight: '200',
            }}
            regions={['south-america']}
            countryCodeEditable
            autoFormat={false}
            value={userState.phone}
            placeholder="Ingrese su teléfono"
            onChange={(phoneNumber) => {
              userDispatch({
                type: ACTIONS.SET_PHONE,
                payload: phoneNumber,
              });
            }}
          />
        </div>
        {/* DNI */}
        <div>
          <h2>DNI</h2>
          <div className="flex gap-2 items-center justify-center">
            <select
              className="bg-transparent rounded-md border border-neutral-300 w-1/6"
              value={userState.dniType}
              onChange={(e) =>
                userDispatch({
                  type: ACTIONS.SET_DNI_TYPE,
                  payload: e.target.value as any, // Asegúrate de validar los valores
                })
              }
            >
              <option value="none">-</option>
              <option value="V">V</option>
              <option value="E">E</option>
              <option value="P">P</option>
              <option value="J">J</option>
              {/* Otras opciones de tipo de DNI aquí... */}
            </select>

            <Input
              placeholder="Número de DNI"
              value={userState.dni}
              onChange={(e) =>
                userDispatch({
                  type: ACTIONS.SET_DNI,
                  payload: e.target.value,
                })
              }
            />
          </div>
        </div>
        {/* Botón de envío */}
        <Button type="submit" disabled={disabledButton}>
          Actualizar información
        </Button>
      </form>
    </div>
  );
}

export default InfoTab;
