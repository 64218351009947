/* eslint-disable no-empty-pattern */
import React from 'react';
import NProgress from 'nprogress';
import { useMutation } from '@apollo/client';
import { useToast, useUser } from '../../hooks';
import InfoTab from './InformationForm';
import { initialUser, userReducer } from './userReducer';
import { UPDATE_USER, USER_ONBOARDING } from '../../graphql/mutation';

type Props = unknown;

export default function PersonalInformationForm({}: Props) {
  const [updateUser] = useMutation(UPDATE_USER);
  const { toast } = useToast();
  const [user, setUser] = useUser();
  const [userState, userDispatch] = React.useReducer(
    userReducer,
    user || initialUser
  );
  const [disabledButton, setDisabledButton] = React.useState(false);

  const [userOnboarding] = useMutation(USER_ONBOARDING);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisabledButton(true);
    NProgress.start();

    // Validaciones básicas (puedes expandir esto según tus necesidades)

    // La longitud del DNI debe ser de 7 a 9 caracteres

    if (userState.dni.length < 7 || userState.dni.length > 9) {
      toast({
        title: 'Validación fallida',
        description: 'El DNI debe tener entre 7 y 9 caracteres',
        // Asume que tienes un variant para errores en el toast
        variant: 'destructive',
      });
      setDisabledButton(false);
      NProgress.done();
      return; // Detiene la ejecución si la validación falla
    }

    // El telefono debe tener al menos 12 caracteres

    if (userState.phone.length < 12) {
      toast({
        title: 'Validación fallida',
        description: 'El teléfono debe tener al menos 12 caracteres',
        // Asume que tienes un variant para errores en el toast
        variant: 'destructive',
      });
      setDisabledButton(false);
      NProgress.done();
      return; // Detiene la ejecución si la validación falla
    }

    if (
      !userState.dni ||
      !userState.dniType ||
      !userState.phone ||
      !userState.addresses.length
    ) {
      toast({
        title: 'Validación fallida',
        description: 'Es obligatorio colocar todos los campos',
        // Asume que tienes un variant para errores en el toast
        variant: 'destructive',
      });
      setDisabledButton(false);
      NProgress.done();
      return; // Detiene la ejecución si la validación falla
    }

    try {
      // const { data } = await updateUser({
      //   variables: {
      //     filter: {
      //       email: user?.email ?? '',
      //     },
      //     record: {
      //       addresses: userState?.addresses ?? [],
      //       dni: userState?.dni ?? '',
      //       dniType: userState?.dniType ?? 'V',
      //       phone: userState?.phone ?? '',
      //     },
      //   },
      // });

      const { data } = await userOnboarding({
        variables: {
          data: {
            userId: user?._id ?? '',
            email: user?.email ?? '',
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            dni: userState?.dni ?? '',
            dniType: userState?.dniType ?? 'V',
            phone: userState?.phone ?? '',
            firstLine: userState?.addresses[0]?.firstLine ?? '',
            secondLine: userState?.addresses[0]?.secondLine ?? '',
            city: userState?.addresses[0]?.city ?? '',
            state: userState?.addresses[0]?.state ?? '',
            postalCode: userState?.addresses[0]?.postalCode ?? '',
          },
        },
      });

      setUser(data?.userOnboarding);
      // set user client storage
      localStorage.setItem(
        'currentClientUser',
        JSON.stringify(data?.userOnboarding)
      );
      toast({
        title: 'Perfil actualizado',
        description: 'Tu perfil ha sido actualizado con éxito.',
        // Asume que tienes un variant para éxito en el toast
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
      toast({
        title: 'Error de actualización',
        description:
          'No se pudo actualizar tu perfil. Por favor, intenta de nuevo más tarde.',
        // Asume que tienes un variant para errores en el toast
        variant: 'destructive',
      });
    } finally {
      setDisabledButton(false);
      NProgress.done();
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center flex-col">
      <InfoTab
        userState={userState}
        userDispatch={userDispatch}
        onSubmit={onSubmit}
        disabledButton={disabledButton}
      />
    </div>
  );
}
