import React from 'react';
import { motion } from 'framer-motion';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import DashboardLayout from '../../components/layout/DashboardLayout';
import { useToast, useUser } from '../../hooks';
import { dashboardConfig } from '../../config/dashboard';
import { Icons } from '../../components/Icons';
import { Button, Input } from '../../components/ui';
import PersonalInformationForm from '../../components/user/PersonalInformationForm';
import Tracking from '../../components/home/hero/tracking/Tracking';

function AppIndex() {
  const [user, setUser] = useUser();
  const router = useRouter();
  const animation = {
    initial: { opacity: 0, scale: 0.975 },
    animate: { opacity: 1, scale: 1 },
    transition: { duration: 0.6 },
  };
  const { toast } = useToast();
  const { data: session } = useSession();

  const [trackingNumber, setTrackingNumber] = React.useState('');

  return (
    <motion.div {...animation}>
      {user?.addresses?.length > 0 ? (
        <DashboardLayout>
          <div className="p-2 gap-4 flex flex-col">
            {/* Input with button */}
            <div className="flex gap-2 w-2/3">
              <Input
                onChange={(e) => {
                  setTrackingNumber(e.target.value);
                }}
                placeholder="Ingresa tu número de envío o flete"
              />
              <Link
                href={`/en/tracking/${trackingNumber}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button onClick={() => {}}>
                  <span>Buscar</span>
                </Button>
              </Link>
            </div>
            <div className="flex flex-wrap gap-4">
              {dashboardConfig?.sidebarNav?.map((nav) => {
                const Icon = Icons[nav.icon || 'arrowRight'];
                return (
                  <button
                    className="flex p-4 flex-col gap-4 items-center justify-center border border-input hover:bg-accent hover:text-accent-foreground inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background "
                    type="button"
                    onClick={() => {
                      router.push(nav.href);
                    }}
                  >
                    <Icon className="h-6 w-6 text-primary-700 " />
                    {nav?.title}
                  </button>
                );
              })}
            </div>
          </div>
        </DashboardLayout>
      ) : (
        <PersonalInformationForm />
      )}
    </motion.div>
  );
}

export default AppIndex;
